
















































































import { Component, Vue, Watch } from 'vue-property-decorator'
//@ts-ignore
import _ from 'lodash'
import NewsComponent from '@/components/NewsComponent.vue'
import SortByClass from '@/views/Group/TopPage/SortByClass.vue'
import SortByNew from '@/views/Group/TopPage/SortByNew.vue'
import { TopicCompact, FileCompact, TopicFileCompact } from '@/models'
import GroupService from '@/services/GroupService'
import GroupSharedFolderService from '@/services/GroupSharedFolderService'
import GroupTopicService from '@/services/GroupTopicService'
import { ETopageSort } from '@/models/Setting/Enum'
import linkifyHtml from "linkify-html";
import store from '@/store'
import Setting from '@/store/modules/Setting'
import { getModule } from 'vuex-module-decorators'
const SettingModule = getModule(Setting, store)

/**
 * f2-001
 * f2-001a
 * グループTOP（分類ごと）
 * グループTOP – 新着順に表示
 */
@Component({ components: { NewsComponent, SortByNew, SortByClass } })
export default class TopPage extends Vue {
  private startLoading: boolean = true
  private topPageSort: ETopageSort = this.$store.state.setting.user_sort
    .toppage_sort_new
  private eTopageSort: any = ETopageSort
  private unreadOnlySortByNew: boolean = false
  private unreadOnlySortByClass: boolean = false
  private unReadCount: number = 0
  private groupNotice: string = ''
  private topics: TopicCompact[] = []
  private files: FileCompact[] = []
  private topicFile: TopicFileCompact[] = []
  private pageTopic: IPaging = {
    page: 1,
    maxPage: 1
  }
  private pageFile: IPaging = {
    page: 1,
    maxPage: 1
  }
  private pageTopicFile: IPaging = {
    page: 1,
    maxPage: 1
  }
  private limit: number = this.$store.state.setting.setting.setting_display
    .number_item_lists

  created() {
    this.handleCreatedPage()
  }

  @Watch('$route.params.groupId')
  handleCreatedPage() {
    this.getGroupDetail()
    if (this.topPageSort === ETopageSort.SORT_BY_NEW) {
      this.getAll()
    } else {
      this.allTopicNotDelete()
      this.allFileNotDelete()
    }
    this.handleCountUnread()
  }

  /**
   * Call API get group notice
   */
  getGroupDetail() {
    GroupService.getGroupById(this.$route.params.groupId, ['notice'])
      .then(res => {
        if (res.status === 200) this.groupNotice = linkifyHtml(res.data.notice || '', { target: "_blank" });
      })
      .catch(err => {
        this.$bvModal.show('modal-error-toppage')
      })
  }

  /**
   * Call API get count of unread
   */
  handleCountUnread() {
    GroupService.getUnreadCount(this.$route.params.groupId)
      .then(res => {
        if (res.status === 200) this.unReadCount = res.data.count_unread
      })
      .catch(err => {
        this.$bvModal.show('modal-error-toppage')
      })
  }

  /**
   * Handle unread count
   */
  subtractReadCount() {
    this.unReadCount = this.unReadCount - 1
  }

  getUnreadText() {
    return this.unReadCount < 100 ? this.unReadCount : '+99'
  }

  handleSortByNew() {
    this.unreadOnlySortByNew = false
    this.topPageSort = ETopageSort.SORT_BY_NEW
    this.getAll()
    this.handleCountUnread()
    SettingModule.CHANGE_SORT_TOPPAGE(ETopageSort.SORT_BY_NEW)
  }

  handleSortByCls() {
    this.unreadOnlySortByClass = false
    this.topPageSort = ETopageSort.SORT_BY_CLS
    this.allTopicNotDelete()
    this.allFileNotDelete()
    this.handleCountUnread()
    SettingModule.CHANGE_SORT_TOPPAGE(ETopageSort.SORT_BY_CLS)
  }

  /**
   * Call API for topics that not detele
   */
  async allTopicNotDelete(loadMore?: boolean) {
    GroupTopicService.getAllTopicNotDelete({
      group_id: this.$route.params.groupId,
      order_by: 'updated_at',
      sorted_by: 'desc',
      page: this.pageTopic.page,
      limit: this.limit,
      unread_only: this.unreadOnlySortByClass ? '1' : '0'
    })
      .then(res => {
        if (res.status === 200) {
          if (loadMore) {
            this.topics = [
              ...this.topics,
              ...res.data.data.map((item: any) => new TopicCompact(item))
            ]
          } else {
            this.topics = res.data.data.map(
              (item: any) => new TopicCompact(item)
            )
          }
          this.pageTopic.maxPage = res.data.last_page
        }
      })
      .catch(err => {
        this.$bvModal.show('modal-error-toppage')
      })
      .finally(() => {
        this.startLoading = false
      })
  }

  /**
   * Load more for topic
   */
  loadmoreTopic() {
    ++this.pageTopic.page
    this.allTopicNotDelete(true)
  }

  /**
   * Call API for shared folder that not detele
   */
  async allFileNotDelete(loadMore?: boolean) {
    GroupSharedFolderService.getFileNotDeleteWithoutTopicAndComment({
      group_id: Number(this.$route.params.groupId),
      order_by: 'updated_at',
      sorted_by: 'desc',
      page: this.pageFile.page,
      limit: this.limit,
      unread_only: this.unreadOnlySortByClass ? '1' : '0'
    })
      .then(res => {
        if (res.status === 200) {
          if (loadMore) {
            this.files = [...this.files, ...res.data.data]
          } else {
            this.files = res.data.data
          }
          this.pageFile.maxPage = res.data.last_page
        }
      })
      .catch(err => {
        this.$bvModal.show('modal-error-toppage')
      })
      .finally(() => {
        this.startLoading = false
      })
  }

  /**
   * Load more for shared folder
   */
  loadmoreFile() {
    ++this.pageFile.page
    this.allFileNotDelete(true)
  }

  /**
   * Get all topics and files that unread only
   */
  async getAllUnread() {
    if (this.unReadCount) {
      if (this.topPageSort === ETopageSort.SORT_BY_NEW) {
        this.unreadOnlySortByNew = !this.unreadOnlySortByNew
        this.pageTopicFile.page = 1
        await this.getAll()
      } else {
        this.unreadOnlySortByClass = !this.unreadOnlySortByClass
        this.pageTopic.page = 1
        this.pageFile.page = 1
        await this.allTopicNotDelete()
        await this.allFileNotDelete()
      }
    }
  }

  /**
   * Get all topics and files by time
   */
  async getAll(loadMore?: boolean) {
    GroupService.getTopicFileCompact({
      group_id: this.$route.params.groupId,
      order_by: 'updated_at',
      sorted_by: 'desc',
      page: this.pageTopicFile.page,
      limit: this.limit,
      unread_only: this.unreadOnlySortByNew ? '1' : '0'
    })
      .then(res => {
        if (res.status === 200) {
          const data = res.data.data.map(
            (item: any) => new TopicFileCompact(item)
          )
          if (loadMore) {
            this.topicFile = [...this.topicFile, ...data]
          } else {
            this.topicFile = data
          }
          this.pageTopicFile.maxPage = res.data.last_page
        }
      })
      .catch(err => {
        this.$bvModal.show('modal-error-toppage')
      })
      .finally(() => {
        this.startLoading = false
      })
  }

  /**
   * Load more for topc-file
   */
  loadmoreTopicFile() {
    ++this.pageTopicFile.page
    this.getAll(true)
  }
}

export interface IPaging {
  page: number
  maxPage: number
}
