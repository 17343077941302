































































import { Prop, Component, Vue } from 'vue-property-decorator'
import { TopicCompact, FileCompact } from '@/models'
import ListTopic from '@/views/Group/TopPage/NewFeed/ListTopic.vue'
import SharedFolderList from '@/views/Group/TopPage/SharedFolder/SharedFolderList.vue'
import { IPaging } from './TopPage.vue'

/**
 * f2-001
 * グループTOP（分類ごと）
 */
@Component({
  components: {
    ListTopic,
    SharedFolderList
  }
})
export default class SortByClassPage extends Vue {
  @Prop() private topics!: TopicCompact[]
  @Prop() private files!: FileCompact[]
  @Prop() private pageTopic!: IPaging
  @Prop() private pageFile!: IPaging
  @Prop() private startLoading!: boolean

  /**
   * emit load more for group topic
   */
  loadmoreTopic() {
    this.$emit('loadmoreTopic')
  }

  /**
   * emit load more for group shared folder
   */
  loadmoreFile() {
    this.$emit('loadmoreFile')
  }

  /**
   * Handle unread count
   */
  subtractReadCount() {
    this.$emit('subtractReadCount')
  }
}
