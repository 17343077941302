












































import { Prop, Component, Vue } from 'vue-property-decorator'
import { TopicFileCompact } from '@/models'
import ListTopic from '@/views/Group/TopPage/NewFeed/ListTopic.vue'
import SharedFolderList from '@/views/Group/TopPage/SharedFolder/SharedFolderList.vue'
import { IPaging } from './TopPage.vue'

/**
 * f2-001a
 * グループTOP – 新着順に表示
 */
@Component({ components: { ListTopic, SharedFolderList } })
export default class SortByNewPage extends Vue {
  @Prop() private topicFile!: TopicFileCompact[]
  @Prop() private pageTopicFile!: IPaging
  @Prop() private startLoading!: boolean

  /**
   * Load more for topc-file
   */
  loadmoreTopicFile() {
    this.$emit('loadmoreTopicFile')
  }

  /**
   * Handle unread count
   */
  subtractReadCount() {
    this.$emit('subtractReadCount')
  }
}
